var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "main-dashborad",
      staticClass: "main-body",
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.fullscreenChange.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("div", { staticClass: "card cardcontents maincardlayer" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card-header non-label-icon mapheader maincardheader topmainlayer",
                },
                [
                  _vm._m(0),
                  _c(
                    "q-btn",
                    {
                      staticClass: "mainmapstart fullscreen-btn",
                      attrs: {
                        flat: "",
                        round: "",
                        color: "white",
                        icon: _vm.fullscreen ? "fullscreen_exit" : "fullscreen",
                      },
                      on: { click: _vm.clickFullScreen },
                    },
                    [
                      _vm.fullscreen
                        ? _c("q-tooltip", [
                            _vm._v(
                              " " + _vm._s(_vm.setLabel("LBL0000327")) + " "
                            ),
                          ])
                        : _c("q-tooltip", [
                            _vm._v(
                              " " + _vm._s(_vm.setLabel("LBL0000328")) + " "
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "card-more main-header-input" },
                    [
                      _c("c-datepicker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.mapObj.activeTask === 1,
                            expression: "mapObj.activeTask===1",
                          },
                        ],
                        attrs: {
                          range: true,
                          appendToBody: false,
                          name: "workPermitPeriod",
                        },
                        on: { datachange: _vm.getMapData },
                        model: {
                          value: _vm.mapObj.taskParam.workPermitPeriod,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.mapObj.taskParam,
                              "workPermitPeriod",
                              $$v
                            )
                          },
                          expression: "mapObj.taskParam.workPermitPeriod",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      staticClass: "mainmapstart startstopbtn",
                      attrs: {
                        outline: !_vm.mapObj.interval.isPause,
                        color: _vm.mapObj.interval.isPause
                          ? "green-6"
                          : "white",
                        "text-color": _vm.mapObj.interval.isPause
                          ? "white"
                          : "white",
                        size: "11px",
                        label: _vm.mapObj.interval.isPause
                          ? _vm.$comm.getLangLabel("LBL0000338")
                          : _vm.$comm.getLangLabel("LBL0000339"),
                        icon: _vm.mapObj.interval.isPause
                          ? "restart_alt"
                          : "pause",
                      },
                      on: { click: _vm.pause },
                    },
                    [_c("q-tooltip", [_vm._v("작업허가 위치 Rotate")])],
                    1
                  ),
                  _vm.mapObj.interval.isPause
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "card-more main-header-input mainmapstartSecond",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              label: "",
                              prefix: "반복 시간",
                              suffix: "(초)",
                              type: "number",
                              name: "settingSeconds",
                            },
                            model: {
                              value: _vm.settingSeconds,
                              callback: function ($$v) {
                                _vm.settingSeconds = $$v
                              },
                              expression: "settingSeconds",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  ref: "carousel",
                  staticClass: "card-body maincardbody",
                  staticStyle: { padding: "15px !important" },
                  attrs: { loading: _vm.mapObj.loading },
                },
                [
                  !_vm.mapObj.loading
                    ? [
                        _vm.mapObj.maps && _vm.mapObj.maps.length > 0
                          ? _c(
                              "q-carousel",
                              {
                                staticClass: "workPermit-carousel",
                                attrs: {
                                  swipeable: "",
                                  animated: "",
                                  arrows: "",
                                  infinite: "",
                                  "control-type": "regular",
                                  "control-color": "grey-6",
                                },
                                on: { transition: _vm.transDisplay },
                                model: {
                                  value: _vm.mapObj.slide,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.mapObj, "slide", $$v)
                                  },
                                  expression: "mapObj.slide",
                                },
                              },
                              [
                                _vm._l(_vm.mapObj.maps, function (map, idx) {
                                  return _c(
                                    "q-carousel-slide",
                                    {
                                      key: idx,
                                      attrs: {
                                        name: idx,
                                        "img-src": map.mapSrc,
                                      },
                                    },
                                    [
                                      [
                                        _c("div", {
                                          staticClass: "mapTitleDiv",
                                          domProps: {
                                            textContent: _vm._s(map.mapName),
                                          },
                                        }),
                                        _vm.mapObj.activeTask === 1
                                          ? _vm._l(
                                              map.maps,
                                              function (item, idx) {
                                                return _c(
                                                  "VueDraggableResizable",
                                                  {
                                                    key: idx,
                                                    ref: "markImage",
                                                    refInFor: true,
                                                    staticClass:
                                                      "mainMarkImage",
                                                    class:
                                                      item.idx ===
                                                      _vm.mapObj.maps[0]
                                                        .haveWorkPermitIdxs[
                                                        _vm.mapObj
                                                          .activeMarked - 1
                                                      ]
                                                        ? "selected"
                                                        : "",
                                                    attrs: {
                                                      resizable: false,
                                                      parent: true,
                                                      draggable: false,
                                                      x:
                                                        item.locationXcoordinate *
                                                        _vm.mapRate *
                                                        _vm.mapObj.ratio,
                                                      y:
                                                        item.locationYcoordinate *
                                                        _vm.mapRate *
                                                        _vm.mapObj.ratio,
                                                      w: 40,
                                                      h: 40,
                                                      grid: [20, 20],
                                                    },
                                                  },
                                                  [
                                                    _c("c-map-marker", {
                                                      attrs: {
                                                        isSelected:
                                                          item.idx ===
                                                          _vm.mapObj.maps[0]
                                                            .haveWorkPermitIdxs[
                                                            _vm.mapObj
                                                              .activeMarked - 1
                                                          ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          map.otherMaps,
                                          function (location, idx) {
                                            return _c(
                                              "VueDraggableResizable",
                                              {
                                                key: "other" + idx,
                                                ref:
                                                  "locationMark" +
                                                  location.mdmLocationId,
                                                refInFor: true,
                                                staticClass: "mainMarkImage",
                                                class:
                                                  location.idx ===
                                                  _vm.mapObj.maps[0]
                                                    .haveWorkPermitIdxs[
                                                    _vm.mapObj.activeMarked - 1
                                                  ]
                                                    ? "selected"
                                                    : "",
                                                staticStyle: { "z-index": "1" },
                                                attrs: {
                                                  parent: true,
                                                  draggable: false,
                                                  resizable: false,
                                                  x:
                                                    location.x *
                                                    _vm.mapRate *
                                                    _vm.mapObj.ratio,
                                                  y:
                                                    location.y *
                                                    _vm.mapRate *
                                                    _vm.mapObj.ratio,
                                                  w: 40,
                                                  h: 40,
                                                  grid: [20, 20],
                                                },
                                              },
                                              [
                                                _c("c-map-marker", {
                                                  attrs: {
                                                    isSelected:
                                                      location.idx ===
                                                      _vm.mapObj.maps[0]
                                                        .haveWorkPermitIdxs[
                                                        _vm.mapObj
                                                          .activeMarked - 1
                                                      ],
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        _vm._l(map.areas, function (area, idx) {
                                          return _c(
                                            "VueDraggableResizable",
                                            {
                                              key: "area" + idx,
                                              staticClass: "my-class-display",
                                              staticStyle: { "z-index": "1" },
                                              attrs: {
                                                parent: true,
                                                draggable: false,
                                                resizable: false,
                                                x:
                                                  area.x *
                                                  _vm.mapRate *
                                                  _vm.mapObj.ratio,
                                                y:
                                                  area.y *
                                                  _vm.mapRate *
                                                  _vm.mapObj.ratio,
                                                w:
                                                  area.w *
                                                  _vm.mapRate *
                                                  _vm.mapObj.ratio,
                                                h:
                                                  area.h *
                                                  _vm.mapRate *
                                                  _vm.mapObj.ratio,
                                                grid: [20, 20],
                                              },
                                            },
                                            [
                                              _c(
                                                "q-menu",
                                                {
                                                  ref: "areaproxy" + area.idx,
                                                  refInFor: true,
                                                  attrs: {
                                                    persistent: true,
                                                    "content-class":
                                                      "mainMapMenuLayer",
                                                    anchor: "top right",
                                                    self: "top left",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.selectedWorkPermit
                                                    .workPermits,
                                                  function (workPermit, idx) {
                                                    return _c(
                                                      "q-card",
                                                      {
                                                        key: idx,
                                                        class: [
                                                          "main-work-permit-card",
                                                          _vm.setStepClass(
                                                            workPermit.swpStepCd
                                                          ),
                                                        ],
                                                        attrs: {
                                                          flat: "",
                                                          bordered: "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "q-item",
                                                          {
                                                            staticClass:
                                                              "main-work-permit-card-section main-work-permit-card-item",
                                                          },
                                                          [
                                                            _c(
                                                              "q-item-section",
                                                              {
                                                                attrs: {
                                                                  avatar: "",
                                                                },
                                                              },
                                                              [
                                                                _c("q-badge", {
                                                                  attrs: {
                                                                    rounded: "",
                                                                    color:
                                                                      _vm.setStepColor(
                                                                        workPermit.swpStepCd
                                                                      ),
                                                                    label:
                                                                      workPermit.swpStepName,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "q-item-section",
                                                              [
                                                                _c(
                                                                  "q-item-label",
                                                                  _vm._l(
                                                                    _vm.setTypeIcons(
                                                                      workPermit.sopWorkTypeCds
                                                                    ),
                                                                    function (
                                                                      icon,
                                                                      iconIdx
                                                                    ) {
                                                                      return _c(
                                                                        "q-icon",
                                                                        {
                                                                          key: iconIdx,
                                                                          staticClass:
                                                                            "text-red",
                                                                          attrs:
                                                                            {
                                                                              name: icon,
                                                                              size: "25px",
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "q-card-section",
                                                          {
                                                            staticClass:
                                                              "main-work-permit-card-section",
                                                          },
                                                          [
                                                            _c("q-icon", {
                                                              staticClass:
                                                                "text-indigo",
                                                              attrs: {
                                                                name: "chevron_right",
                                                              },
                                                            }),
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  workPermit.workSummary
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " (" +
                                                                _vm._s(
                                                                  workPermit.workTime
                                                                ) +
                                                                ") "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        workPermit.vendorName
                                                          ? _c(
                                                              "q-card-section",
                                                              {
                                                                staticClass:
                                                                  "main-work-permit-card-section",
                                                              },
                                                              [
                                                                _c("q-icon", {
                                                                  staticClass:
                                                                    "text-indigo",
                                                                  attrs: {
                                                                    name: "chevron_right",
                                                                  },
                                                                }),
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "작업업체 : " +
                                                                      _vm._s(
                                                                        workPermit.vendorName
                                                                      )
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        workPermit.processName ||
                                                        workPermit.equipmentName
                                                          ? _c(
                                                              "q-card-section",
                                                              {
                                                                staticClass:
                                                                  "main-work-permit-card-section",
                                                              },
                                                              [
                                                                workPermit.processName
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "main-work-permit-card-proc",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "q-chip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                outline:
                                                                                  "",
                                                                                square:
                                                                                  "",
                                                                                color:
                                                                                  "blue",
                                                                                "text-color":
                                                                                  "white",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  workPermit.processName
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                workPermit.equipmentName
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "main-work-permit-card-equip",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "q-chip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                outline:
                                                                                  "",
                                                                                square:
                                                                                  "",
                                                                                color:
                                                                                  "green",
                                                                                "text-color":
                                                                                  "white",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  workPermit.equipmentName
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                      ],
                                    ],
                                    2
                                  )
                                }),
                                _c(
                                  "q-carousel-slide",
                                  {
                                    staticClass: "etc-carousel",
                                    attrs: { name: _vm.mapObj.maps.length },
                                  },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-7" }, [
                                        _c(
                                          "div",
                                          { staticClass: "cardcontents" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card-header card-etc-header",
                                              },
                                              [
                                                _vm._v(
                                                  " 일일 안전작업허가 목록 "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card-body nopadgrid",
                                              },
                                              [
                                                _c("c-table", {
                                                  ref: "gridWorkPermit",
                                                  attrs: {
                                                    tableId: "gridWorkPermit",
                                                    isTitle: false,
                                                    isDashboard: true,
                                                    columnSetting: false,
                                                    usePaging: false,
                                                    filtering: false,
                                                    isFullScreen: false,
                                                    hideBottom: true,
                                                    gridHeight:
                                                      _vm.gridHeight + "px",
                                                    columns:
                                                      _vm.gridWorkPermit
                                                        .columns,
                                                    data: _vm.gridWorkPermit
                                                      .data,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "col-5" }, [
                                        _c(
                                          "div",
                                          { staticClass: "cardcontents" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card-header card-etc-header",
                                              },
                                              [_vm._v(" 하역/선적/출하 현황 ")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card-body nopadgrid",
                                              },
                                              [
                                                _c("c-table", {
                                                  ref: "gridUnload",
                                                  attrs: {
                                                    tableId: "gridUnload",
                                                    isTitle: false,
                                                    isDashboard: true,
                                                    columnSetting: false,
                                                    usePaging: false,
                                                    filtering: false,
                                                    isFullScreen: false,
                                                    hideBottom: true,
                                                    gridHeight:
                                                      _vm.gridHeight / 2 + "px",
                                                    columns:
                                                      _vm.gridUnload.columns,
                                                    data: _vm.gridUnload.data,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "customArea",
                                                        fn: function ({
                                                          props,
                                                          col,
                                                        }) {
                                                          return [
                                                            col.name ===
                                                            "mdmUnloadTypeName"
                                                              ? [
                                                                  _c(
                                                                    "q-chip",
                                                                    {
                                                                      staticClass:
                                                                        "full-size-chip",
                                                                      attrs: {
                                                                        color:
                                                                          props
                                                                            .row
                                                                            .mdmUnloadTypeName ==
                                                                          "하역"
                                                                            ? "orange"
                                                                            : "blue",
                                                                        outline:
                                                                          "",
                                                                        square:
                                                                          "",
                                                                        "text-color":
                                                                          "white",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            props
                                                                              .row
                                                                              .mdmUnloadTypeName
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3347881496
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card-body nopadgrid",
                                              },
                                              [
                                                _c("c-table", {
                                                  ref: "gridShip",
                                                  attrs: {
                                                    tableId: "gridShip",
                                                    isTitle: false,
                                                    isDashboard: true,
                                                    columnSetting: false,
                                                    usePaging: false,
                                                    filtering: false,
                                                    isFullScreen: false,
                                                    hideBottom: true,
                                                    gridHeight:
                                                      _vm.gridHeight / 2 + "px",
                                                    columns:
                                                      _vm.gridShip.columns,
                                                    data: _vm.gridShip.data,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ],
                              2
                            )
                          : _c("el-empty", {
                              attrs: {
                                "image-size": 435,
                                description:
                                  _vm.$comm.getLangMessage("MSG0000122"),
                              },
                            }),
                      ]
                    : void 0,
                ],
                2
              ),
            ]),
          ]
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c(
        "q-inner-loading",
        {
          attrs: {
            showing: _vm.mapObj.loading,
            label: "화면 조정중입니다.",
            "label-class": "text-blue-grey",
            "label-style": "font-size: 8em",
            size: "12em",
          },
        },
        [
          _c("q-spinner-grid", { attrs: { size: "12em", color: "blue-grey" } }),
          _c(
            "div",
            {
              staticClass: "text-blue-grey",
              staticStyle: { "font-size": "3em" },
            },
            [_vm._v(" 화면 조정중 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "title1" }, [_vm._v("안전작업허가서 현황")]),
      _vm._v(" "),
      _c("span", { staticClass: "title3" }, [_vm._v("SWP")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }